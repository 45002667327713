import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import { IconButton, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { activeAlbumIdVar, drawerVar } from '../../../../../providers/apollo/cache'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'
import useBucket from '../../../hooks/useBucket'
import useBucketMigrationProgress from '../../../hooks/useBucketMigrationProgress'
import useBucketConvertProgress from '../../../hooks/useBucketConvertProgress'

export default function AlbumMoveIcon() {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const { selectedAlbums } = useSelectedAlbums()
  const containRootAlbum = Array.from(selectedAlbums.keys()).includes(bucketId as string)
  const { bucketUser } = useBucket()
  const albumMovePermission = bucketUser?.permissions?.albumUpdate
  const isMigrationInProgressForBucket = useBucketMigrationProgress()
  const isBucketConvertInProgressForBucket = useBucketConvertProgress()

  let title = 'Move Album'
  if (isMigrationInProgressForBucket || isBucketConvertInProgressForBucket) {
    title = 'Move album is disabled during the migration process'
  } else if (!albumMovePermission) {
    title = 'Your role for this bucket does not allow you to move albums.'
  }

  const disabled =
    containRootAlbum ||
    !(albumId || selectedAlbums.size !== 0) ||
    !albumMovePermission ||
    isMigrationInProgressForBucket ||
    isBucketConvertInProgressForBucket

  const handleClick = () => {
    if (!albumId && selectedAlbums.size < 1) return

    if (albumId) activeAlbumIdVar(albumId)
    drawerVar('bucketAlbumMove')
  }

  return (
    <Tooltip title={title} arrow>
      <div>
        <IconButton color="inherit" aria-label="Move Album" disabled={disabled} onClick={handleClick} id="move-media" data-test="move-media">
          <DriveFileMoveOutlinedIcon />
        </IconButton>
      </div>
    </Tooltip>
  )
}
