import { Button, Divider, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import ExternalLink from '@mui/material/Link'
import { getAuth } from 'firebase/auth'
import VerifyEmailLogo from '../../../../svg/VerifyEmailLogo'
import useEmailVerification from '../../hooks/useEmailVerification'
import { State } from './types'
import useDecodedIdToken from '../../../auth/hooks/useDecodedIdToken'

const ContainerDiv = styled('div')((props) => ({
  border: '1px dashed #999',
  margin: '20px',
  padding: '90px 20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  [props.theme.breakpoints.down('sm')]: {
    padding: 0,
    border: 'none'
  }
}))

const TitleTypography = styled(Typography)((props) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '10px 0 20px',
  [props.theme.breakpoints.down('sm')]: {
    margin: '20px 0 40px'
  }
}))

const ContentDiv = styled('div')((props) => ({
  paddingTop: '10px',
  width: '95%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  [props.theme.breakpoints.up('md')]: {
    width: '65%',
    margin: 0
  },
  [props.theme.breakpoints.up('lg')]: {
    width: '45%',
    margin: 0
  }
}))

const StyledDivider = styled(Divider)((props) => ({
  width: '100%',
  marginBottom: '20px',
  backgroundColor: '#024b9e',
  [props.theme.breakpoints.down('sm')]: {
    margin: 0,
    display: 'none'
  }
}))

const StyledButton = styled(Button)((props) => ({
  marginRight: '20px',
  marginBottom: '10px',
  textTransform: 'none',
  [props.theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

const ButtonContentDiv = styled('div')((props) => ({
  paddingBottom: '10px',
  marginLeft: '30px',
  textAlign: 'center',
  marginTop: '20px',
  [props.theme.breakpoints.down('sm')]: {
    marginLeft: 0
  }
}))

const StoreImage = styled('img')(({ theme }) => ({
  padding: '10px',
  marginRight: '10px',
  width: '110px',
  [theme.breakpoints.up('md')]: {
    width: '130px',
    marginRight: '20px'
  },
  [theme.breakpoints.up('lg')]: {
    width: '180px',
    marginRight: '30px'
  }
}))

export default function EmailNotVerified() {
  const theme = useTheme()
  const isSmallSize = useMediaQuery(theme.breakpoints.down('md'))
  const { resendEmailConfirmation, resendState, resetSendState } = useEmailVerification()
  const { customClaims } = useDecodedIdToken(true)
  const isPUCUser = customClaims?.isPUCUser ?? false

  const email = getAuth().currentUser?.email ?? 'your email'

  let title = 'Verify Your Email'
  let subtitle = `In order to use Photobucket, you must verify your email. For security purposes, please click the link we sent you at ${email} in order to access your account. To change your email submit a support ticket.`

  if (isPUCUser) {
    subtitle = `We sent an email to ${email}.  Click the one time login link found in that email to gain access to your account.  In the future, you can send a similar one-time login link from the Sign In page. If you need to change your email, please submit a support ticket.`
    title = 'An email is on its way!'
  }

  const handleChangeEmail = () => {
    window.open('https://support.photobucket.com/hc/en-us/requests/new')
  }

  const handleResendEmail = () => {
    resendEmailConfirmation()
  }

  return (
    <ContainerDiv style={{ minHeight: 'calc(100vh - 270px)' }}>
      <VerifyEmailLogo width={isSmallSize ? 121 : 188} height={isSmallSize ? 76 : 120} />
      <ContentDiv>
        <TitleTypography variant="h2" id="secure-email-title" data-test="secure-email-title">
          {title}
        </TitleTypography>
        <Typography variant="body1">{subtitle}</Typography>

        <ButtonContentDiv>
          <StyledButton
            disabled={[State.SUCCESS, State.LOADING].includes(resendState)}
            data-test="verifybtn-send-again"
            id="verifybtn-send-again"
            size="large"
            variant="contained"
            onClick={handleResendEmail}
          >
            Send Again
          </StyledButton>
          <StyledButton data-test="verifybtn-change-email" id="verifybtn-send-again" size="large" variant="contained" onClick={handleChangeEmail}>
            Submit Request To Support
          </StyledButton>
          {resendState === State.SUCCESS && (
            <>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Didn&rsquo;t get an email?
              </Typography>
              <StyledButton data-test="verifybtn-try-again" id="verifybtn-try-again" size="large" variant="outlined" onClick={resetSendState}>
                Try again?
              </StyledButton>
            </>
          )}
        </ButtonContentDiv>
        <StyledDivider />
      </ContentDiv>
      {isPUCUser && (
        <ButtonContentDiv style={{ marginTop: '0px' }}>
          <ExternalLink
            href="https://apps.apple.com/us/app/photobucket-backup/id314439840?utm_medium=web&utm_source=discount_page&utm_content=device_block&utm_campaign=v2_apple"
            data-test="ios-app-store-badge"
          >
            <StoreImage src="//content.photobucket.com/img/homepage/app-store-black.webp" alt="App Store" loading="lazy" />
          </ExternalLink>
          <ExternalLink
            href="https://play.google.com/store/apps/details?id=com.photobucket.android&utm_medium=web&utm_source=discount_page&utm_content=device_block&utm_campaign=v2_android"
            data-test="android-app-store-badge"
          >
            <StoreImage src="//content.photobucket.com/img/homepage/google-play-black.webp" alt="Google Play" loading="lazy" />
          </ExternalLink>
        </ButtonContentDiv>
      )}
    </ContainerDiv>
  )
}
