import { useApolloClient, useReactiveVar } from '@apollo/client'
import { IosShareOutlined } from '@mui/icons-material'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import Hosting from '@mui/icons-material/Code'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ScreenShareIcon from '@mui/icons-material/ScreenShareOutlined'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Stack } from '@mui/material'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useFlag } from '@unleash/proxy-client-react'
import moment from 'moment'
import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PasswordVisibilityAdornment from '../../../../components/forms/PasswordVisibilityAdornment'
import { baseDomain } from '../../../../config'
import theme from '../../../../config/theme'
import { BucketAlbumFragmentFragmentDoc, BucketShareType, BucketSharesDocument } from '../../../../graphql/generated'
import { activeAlbumIdVar, modalVar } from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useAlerts from '../../hooks/useAlerts'
import useBucket from '../../hooks/useBucket'
import useBucketPlan from '../../hooks/useBucketPlan'
import useBucketShareCreate from '../../hooks/useBucketShareCreate'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import Sharing from './hosting/Sharing'
import SharingPost from './post/Sharing'

interface Fields {
  password?: string | null
  passwordEnabled?: boolean | null
  title?: string | null
  scheduledDeletionAt?: string | null
  days?: 7 | 30 | 60 | 90 | null
}

export default function SharingLinkCreate() {
  const isHostingEnabled = useFlag('isBucketHostingEnabled')
  const isShareModalTabEnabled = useFlag('isShareModalTabEnabled')
  const [tabValue, setTabValue] = useState(isShareModalTabEnabled ? '1' : '2')
  const [invisible, setInvisible] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const { restrictions } = useBucketPlan()
  const { createAlert } = useAlerts()
  const [errors, setErrors] = useState<{ title?: string }>({})
  const [fields, setFields] = useState<Fields>({ passwordEnabled: false })

  const [currentLink, setCurrentLink] = useState<string>()
  const modal = useReactiveVar(modalVar)
  const { selectedMedia, clearSelectedMedia } = useSelectedMedia()
  const { bucketId } = useParams<{ bucketId: string }>()
  const { bucket } = useBucket()
  const albumId = useReactiveVar(activeAlbumIdVar)
  const mediaIds = Array.from(selectedMedia.keys())
  const { mutation, results } = useBucketShareCreate()
  const defaultText = fields.scheduledDeletionAt ? moment(fields.scheduledDeletionAt).format('MMM DD, YYYY') : '30 days'
  const share = results.data?.bucketShareCreate
  const shareLink = share ? `https://${baseDomain}/share/${share.id}` : null
  const hostingRestricted = restrictions.hosting
  const apolloCache = useApolloClient()
  const album = apolloCache.readFragment({ id: `BucketAlbum:${albumId}`, fragment: BucketAlbumFragmentFragmentDoc })
  // Since we already have the bucket id, pass in BUCKET as the albumId var so we know we're sharing a bucket, not an album
  const isSharingBucket = albumId === BucketShareType.Bucket

  const handleChangeDaysField = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prevFields) => {
      let { scheduledDeletionAt } = prevFields
      if (event.target.name === 'days') {
        scheduledDeletionAt = moment().add(event.target.value, 'days').toISOString()
      }

      return {
        ...prevFields,
        scheduledDeletionAt,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleChangePasswordField = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prevFields) => {
      return {
        ...prevFields,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleChangeTitleField = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prevFields) => {
      if (event.target.name === 'title' && event.target.value.trim() !== '') {
        setErrors({}) // reset the title error when the title field is not empty
      }

      return {
        ...prevFields,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleExpirationCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFields({ ...fields, days: 30, scheduledDeletionAt: moment().add(30, 'days').toISOString() }) // default to 30 days
      return
    }
    setFields({ ...fields, days: null, scheduledDeletionAt: null })
  }

  const handlePasswordCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFields({ ...fields, passwordEnabled: true, password: null })
      return
    }
    setFields({ ...fields, passwordEnabled: false, password: null })
  }

  const handleClose = () => {
    setTabValue('1')
    activeAlbumIdVar(null)
    modalVar(null)
    results.reset()
    setFields({ passwordEnabled: false })
    setErrors({})
  }

  const handleCopy = async () => {
    if (!shareLink) return
    try {
      const clipBoardItem = new ClipboardItem({ 'text/plain': new Blob([shareLink], { type: 'text/plain' }) })
      await navigator.clipboard.write([clipBoardItem])
      setInvisible(false)
      setTimeout(() => setInvisible(true), 2000)
    } catch (error) {
      logger.error({ error })
    }
  }

  const handleNavigate = () => {
    handleClose()
    if (share) {
      navigate(`/bucket/${bucketId}/share`)
    }
  }

  const handleSubmit = () => {
    if (!bucketId) return
    if (!mediaIds.length && !albumId) return

    const { title, scheduledDeletionAt, password } = fields
    let variables
    if (albumId) {
      variables = {
        bucketId,
        data: { title, scheduledDeletionAt, password, albumId, shareType: isSharingBucket ? BucketShareType.Bucket : BucketShareType.Album }
      }
    } else if (mediaIds) {
      variables = { bucketId, data: { title, scheduledDeletionAt, password, mediaIds, shareType: BucketShareType.Media } }
    }

    mutation({
      variables,
      update: async (cache, { data }) => {
        if (!data?.bucketShareCreate) return

        clearSelectedMedia()
        activeAlbumIdVar(null)

        const clipBoardItem = new ClipboardItem({
          'text/plain': new Blob([`https://${baseDomain}/share/${data.bucketShareCreate.id}`], { type: 'text/plain' })
        })

        await navigator.clipboard.write([clipBoardItem])
        cache.updateQuery({ query: BucketSharesDocument, variables: { bucketId } }, (current) => {
          // If the query does not exist
          if (!current || !data?.bucketShareCreate) return null

          // If we get here then the query must exist in cache and we will update it with the new album
          const items = [data.bucketShareCreate, ...(current.bucketShares?.items ?? [])]

          return {
            bucketShares: {
              ...current.bucketShares,
              items
            }
          }
        })
      }
    })
  }

  const handleHostingLinkCopy = async () => {
    if (!currentLink) return
    const clipBoardItem = new ClipboardItem({ 'text/plain': new Blob([currentLink], { type: 'text/plain' }) })
    await navigator.clipboard.write([clipBoardItem])
    setInvisible(false)
    setTimeout(() => setInvisible(true), 2000)
    createAlert('Copied to clipboard', 'success')
  }

  // Ignore required due to event being a required first param
  // @ts-ignore
  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  return (
    <Dialog open={modal === 'bucketSharingLinkCreate'} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {shareLink && <>Sharing Link created!</>}
        {!shareLink && !albumId && (
          <>
            {mediaIds.length} {mediaIds.length > 1 ? 'items' : 'item'}
          </>
        )}
        {!shareLink && albumId && <>{isSharingBucket ? bucket?.title : album?.title}</>}
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <TabContext value={tabValue}>
          <TabList onChange={handleTabChange} aria-label="Share modal tabs" variant="fullWidth">
            {isShareModalTabEnabled && <Tab label="Sharing Link" value="1" icon={<ScreenShareIcon />} iconPosition="start" />}
            {!albumId && isHostingEnabled && !shareLink && !hostingRestricted && (
              <Tab label="Host" value="2" icon={<Hosting />} iconPosition="start" />
            )}
            {!albumId && <Tab label="Post" value="3" icon={<IosShareOutlined />} iconPosition="start" />}
          </TabList>

          {isShareModalTabEnabled && (
            <TabPanel value="1" style={{ padding: 0 }}>
              {results.loading && <LinearProgress />}

              <Box sx={{ padding: '20px 24px' }}>
                {!shareLink && (
                  <>
                    <TextField
                      sx={{ marginTop: 1 }}
                      label="Title"
                      name="title"
                      value={fields.title ?? ''}
                      onChange={handleChangeTitleField}
                      fullWidth
                      error={!!errors.title}
                      helperText={errors.title}
                    />
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginTop: 1 }}>
                      <FormControlLabel
                        control={<Checkbox checked={!!fields.passwordEnabled} onChange={handlePasswordCheckboxChange} />}
                        label="Password Protected"
                      />
                      <TextField
                        sx={{ marginTop: 1 }}
                        label="Password"
                        name="password"
                        value={fields.password ?? ''}
                        onChange={handleChangePasswordField}
                        type={showPassword ? 'text' : 'password'}
                        disabled={!fields.passwordEnabled}
                        InputProps={{
                          endAdornment: <PasswordVisibilityAdornment showPassword={showPassword} handleClickShowPassword={handleClickShowPassword} />
                        }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginTop: 1 }}>
                      <FormControlLabel
                        control={<Checkbox checked={!!fields.scheduledDeletionAt} onChange={handleExpirationCheckboxChange} />}
                        label="Enable Link Expiration"
                      />
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarIcon />
                            </InputAdornment>
                          )
                        }}
                        value={fields.days ?? 0}
                        onChange={handleChangeDaysField}
                        name="days"
                        select
                        disabled={!fields.scheduledDeletionAt}
                      >
                        <MenuItem disabled value={0}>
                          {defaultText}
                        </MenuItem>
                        <MenuItem value={7}>7 days</MenuItem>
                        <MenuItem value={30}>30 days</MenuItem>
                        <MenuItem value={60}>60 days</MenuItem>
                        <MenuItem value={90}>90 days</MenuItem>
                      </TextField>
                    </Box>
                  </>
                )}
                {shareLink && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: theme.palette.customSuccess[50],
                        padding: '15px 10px',
                        margin: '20px 0 10px',
                        borderRadius: '8px',
                        alignItems: 'center'
                      }}
                    >
                      <Stack>
                        <Typography
                          sx={{ display: 'flex', cursor: 'pointer', fontWeight: '600', wordBreak: 'break-word', marginBottom: '8px' }}
                          color={theme.palette.secondary.main}
                          onClick={handleCopy}
                        >
                          <Badge color="success" badgeContent="✓" invisible={invisible} sx={{ marginRight: '8px' }}>
                            <ContentCopyIcon />
                          </Badge>
                          {shareLink}
                        </Typography>
                      </Stack>
                      <Typography color="green">Link copied to clipboard. Paste and send to share with others.</Typography>
                    </Box>
                    <Box>
                      Manage your Sharing Links on the
                      <Button variant="text" onClick={handleNavigate}>
                        Sharing Links
                      </Button>
                      page.
                    </Box>
                  </>
                )}
              </Box>
              <DialogActions style={{ marginTop: '12px' }}>
                {shareLink && (
                  <Button variant="contained" color="primary" onClick={handleClose}>
                    Done
                  </Button>
                )}
                {!shareLink && (
                  <>
                    <Button variant="outlined" color="primary" disabled={results.loading} onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={results.loading || (!!fields.passwordEnabled && !fields.password)}
                      onClick={handleSubmit}
                    >
                      Create Sharing Link
                    </Button>
                  </>
                )}
              </DialogActions>
            </TabPanel>
          )}
          {isHostingEnabled && !shareLink && !hostingRestricted && (
            <TabPanel value="2" style={{ padding: 0 }}>
              <Sharing setCurrentLink={setCurrentLink} handleHostingLinkCopy={handleHostingLinkCopy} />
              <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose}>
                  Done
                </Button>
              </DialogActions>
            </TabPanel>
          )}
          {isShareModalTabEnabled && (
            <TabPanel value="3" style={{ padding: 0 }}>
              <SharingPost mediaCount={mediaIds.length} />
              <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose}>
                  Done
                </Button>
              </DialogActions>
            </TabPanel>
          )}
        </TabContext>
      </DialogContent>
    </Dialog>
  )
}
