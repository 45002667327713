import { useMutation, useReactiveVar } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { BucketDeleteDocument } from '../../../../graphql/generated'
import { modalVar } from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useGAEvent from '../../../auth/hooks/useGAEvent'
import useAlerts from '../../hooks/useAlerts'

export default function ConfirmDeleteDialog() {
  const [textInput, setTextInput] = useState('')
  const modal = useReactiveVar(modalVar)
  const { createAlert } = useAlerts()
  const [deleteBucketMutation, results] = useMutation(BucketDeleteDocument)
  const { bucketId } = useParams<{ bucketId: string }>()
  const { trackEvent } = useGAEvent()
  const userId = getAuth().currentUser?.uid

  const handleClose = () => {
    modalVar(null)
  }

  const handleConfirmDeleteBucket = () => {
    if (!bucketId || results.loading) {
      return
    }
    deleteBucketMutation({
      variables: { bucketId },
      onCompleted: () => {
        trackEvent('bucket_delete', { bucketId, userId })
        window.location.href = '/buckets?deleted=true'
      },
      onError: (e) => {
        logger.error(e)
        createAlert('There was an error deleting this bucket. Please try again later.')
      }
    })
  }

  return (
    <Dialog
      data-test="confirm-delete-bucket-dialog"
      open={modal === 'bucketDelete'}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '629px' // Set your width here
          }
        }
      }}
    >
      <DialogTitle
        data-test="invite-users-title"
        sx={{ fontWeight: 700, fontSize: '20px', textAlign: 'left', padding: { xs: '10px', sm: '20px 24px' } }}
      >
        Delete This Bucket?
      </DialogTitle>
      <DialogContent sx={{ padding: { xs: '20px 10px', sm: '20px 24px' } }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '18px' }}>
          Deleting this bucket will permanently delete all media and albums within it. If it is shared with others, those people will lose access.
          This action is <strong>permanent</strong> and <strong>cannot be undone</strong>.
        </Typography>
        <Typography sx={{ mt: 2, fontSize: '14px', fontWeight: 400, lineHeight: '18px' }}>Type the word “delete” below to confirm:</Typography>
        <TextField
          inputProps={{ 'data-testid': 'delete-bucket-input' }}
          fullWidth
          variant="outlined"
          label={`Type "delete" here to confirm.`}
          size="small"
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          sx={{ mt: 2, maxWidth: '377px' }}
        />
      </DialogContent>
      <DialogActions sx={{ mx: 2, mb: 1, justifyContent: 'flex-end' }}>
        <Button data-testid="delete-bucket-cancel" color="primary" variant="text" sx={{ mr: 2 }} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-testid="confirm-delete-bucket-button"
          disabled={results.loading || textInput?.toLowerCase() !== 'delete'}
          color="error"
          variant="contained"
          onClick={handleConfirmDeleteBucket}
        >
          Delete Bucket
        </Button>
      </DialogActions>
    </Dialog>
  )
}
