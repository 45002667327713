import { useQuery, useReactiveVar } from '@apollo/client'
import { styled } from '@mui/material'
import { useUnleashContext } from '@unleash/proxy-client-react'
import { getAuth } from 'firebase/auth'
import { PropsWithChildren, useEffect, useRef } from 'react'
import Loading from '../../../components/PageLoading'
import { BucketUserStatus, UserProfileDocument } from '../../../graphql/generated'
import { adminUserIdVar, modalVar } from '../../../providers/apollo/cache'
import logger from '../../../utils/logger'
import useBucket from '../hooks/useBucket'
import useBucketGallerySettings from '../hooks/useBucketGallerySettings'
import Alerts from './Alerts'
import ClearSelected from './ClearSelected'
import AppBar from './appbar'
import Banned from './blockers/BucketDisabled'
import EmailNotVerified from './blockers/EmailNotVerified'
import NotFound from './blockers/NotFound'
import Drawer from './drawer'
import AlbumDetailsDrawer from './drawers/AlbumDetails'
import AlbumMoveDrawer from './drawers/AlbumMove'
import MediaDetailsDrawer from './drawers/MediaDetails'
import MediaMoveDrawer from './drawers/MediaMove'
import MediaRestoreDrawer from './drawers/MediaRestore'
import AlbumDeleteModal from './modals/AlbumDelete'
import BucketCreateModal from './modals/BucketCreate'
import BucketDelete from './modals/BucketDelete'
import BucketTrashEmpty from './modals/BucketTrashEmpty'
import BucketUpgrade from './modals/BucketUpgrade'
import InvitationCreateModal from './modals/InvitationCreate'
import MediaDelete from './modals/MediaDelete'
import MediaDeletePermanently from './modals/MediaDeletePermanently'
import SharingLinkCreate from './modals/MediaShareCreate'
import MediaShareDelete from './modals/MediaShareDelete'
import MediaShareUpdate from './modals/MediaShareUpdate'
import TermsAndConditions from './modals/TermsAndConditions'
import { Blocker } from './types'

const Main = styled('main')({
  display: 'flex',
  flex: 1,
  overflowX: 'hidden',
  flexDirection: 'column',
  position: 'relative'
})

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flex: 1
}))

interface BlockerProps {
  blocker: Blocker
}

export function RenderBlocker({ blocker }: Readonly<BlockerProps>) {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppBar currentBucketMenu={false} iconMenu={false} searchBar={false} selectButton={false} uploadButton={false} />
        <Wrapper>
          <Drawer />
          <Main id="main">
            {blocker === Blocker.EMAIL_NOT_VERIFIED && <EmailNotVerified />}
            {blocker === Blocker.BUCKET_DISABLED && <Banned />}
            {blocker === Blocker.NOT_FOUND && <NotFound />}
          </Main>
        </Wrapper>
      </div>
      <Alerts />
    </>
  )
}

export function FullscreenLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  const { bucket, bucketUser, loading } = useBucket()

  if (loading) {
    return <Loading />
  }

  if (!getAuth().currentUser?.emailVerified) {
    return <RenderBlocker blocker={Blocker.EMAIL_NOT_VERIFIED} />
  }

  if (bucket?.isBanned) {
    return <RenderBlocker blocker={Blocker.BUCKET_DISABLED} />
  }

  if (bucketUser?.userStatus !== BucketUserStatus.Active) {
    return <RenderBlocker blocker={Blocker.NOT_FOUND} />
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Wrapper>
        <Main id="main">{children}</Main>
      </Wrapper>
      <AlbumDeleteModal />
      <AlbumDetailsDrawer />
      <Alerts />
      <BucketUpgrade />
      <BucketDelete />
      <ClearSelected />
      <InvitationCreateModal />
      <MediaDelete />
      <MediaDetailsDrawer />
      <MediaMoveDrawer />
      <MediaRestoreDrawer />
      <SharingLinkCreate />
    </div>
  )
}

export function NewAccountLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <AppBar searchBar={false} currentBucketMenu={false} displayDrawerToggle={false} uploadButton={false} iconMenu={false} selectButton={false} />
      <Wrapper>
        <Main id="main">{children}</Main>
      </Wrapper>
      <Alerts />
    </div>
  )
}

export function SettingsLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <AppBar displayDrawerToggle={false} uploadButton={false} iconMenu={false} selectButton={false} />
      <Wrapper>
        <Main id="main">{children}</Main>
      </Wrapper>
      <Alerts />
      <BucketCreateModal />
      <BucketDelete />
      <BucketUpgrade />
      <ClearSelected />
      <InvitationCreateModal />
    </div>
  )
}

export function InviteLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <AppBar
        currentBucketMenu={false}
        displayDrawerToggle={false}
        uploadButton={false}
        iconMenu={false}
        searchBar={false}
        selectButton={false}
        settingsMenu={false}
      />
      <Wrapper sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Main id="main">{children}</Main>
      </Wrapper>
      <BucketUpgrade />
      <Alerts />
    </div>
  )
}

export function TransferLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <AppBar uploadButton={false} iconMenu={false} selectButton={false} />
      <Wrapper sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Main id="main">{children}</Main>
      </Wrapper>
      <Alerts />
    </div>
  )
}

export function DownloadsLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  const { bucket, bucketUser, loading } = useBucket()

  if (loading) {
    return <Loading />
  }

  if (!getAuth().currentUser?.emailVerified) {
    return <RenderBlocker blocker={Blocker.EMAIL_NOT_VERIFIED} />
  }

  if (bucket?.isBanned) {
    return <RenderBlocker blocker={Blocker.BUCKET_DISABLED} />
  }

  if (bucketUser?.userStatus !== BucketUserStatus.Active) {
    return <RenderBlocker blocker={Blocker.NOT_FOUND} />
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppBar iconMenu={false} searchBar={false} selectButton={false} uploadButton={false} />
        <Wrapper>
          <Drawer />
          <Main id="main">{children}</Main>
        </Wrapper>
      </div>
      <Alerts />
      <BucketCreateModal />
      <BucketUpgrade />
    </>
  )
}

export function GalleryLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  const profileQuery = useQuery(UserProfileDocument)
  const profileLoaded = Boolean(profileQuery.data?.userProfile)
  const { bucket, bucketUser, loading } = useBucket()
  const { updateSettings, normalize } = useBucketGallerySettings()
  const unleashContext = useUnleashContext()
  const isAdmin = useReactiveVar(adminUserIdVar)
  const { data } = useQuery(UserProfileDocument, {
    onCompleted: (profileData) => {
      unleashContext({ userId: profileData?.userProfile?.id, properties: { planId: profileData.userProfile?.plan?.plan_name as string } })
    }
  })
  const updateSettingsRef = useRef(updateSettings)
  const normalizeRef = useRef(normalize)
  const desktopSettings = data?.userProfile?.desktopSettings

  useEffect(() => {
    if (!desktopSettings) return

    const newSettings = normalizeRef.current(desktopSettings)
    updateSettingsRef.current(newSettings, { skipMutation: true })
  }, [desktopSettings])

  useEffect(() => {
    if (!isAdmin) {
      const latestTermsAccepted = Boolean(profileQuery.data?.userProfile?.latestTermsAccepted)
      if (profileLoaded && !latestTermsAccepted) {
        logger.info('Terms and conditions not accepted')
        modalVar('termsAndConditions')
      }
    }
  }, [isAdmin, profileLoaded, profileQuery.data?.userProfile?.latestTermsAccepted])

  if (loading) {
    return <Loading />
  }

  if (!getAuth().currentUser?.emailVerified) {
    return <RenderBlocker blocker={Blocker.EMAIL_NOT_VERIFIED} />
  }

  if (bucket?.isBanned) {
    return (
      <>
        <RenderBlocker blocker={Blocker.BUCKET_DISABLED} />
        <BucketCreateModal />
      </>
    )
  }

  if (bucketUser?.userStatus !== BucketUserStatus.Active) {
    return <RenderBlocker blocker={Blocker.NOT_FOUND} />
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppBar />
        <Wrapper>
          <Drawer />
          <Main id="main">{children}</Main>
        </Wrapper>
      </div>
      <AlbumDeleteModal />
      <AlbumDetailsDrawer />
      <AlbumMoveDrawer />
      <Alerts />
      <BucketCreateModal />
      <BucketDelete />
      <BucketTrashEmpty />
      <BucketUpgrade />
      <ClearSelected />
      <InvitationCreateModal />
      <MediaDelete />
      <MediaDeletePermanently />
      <MediaDetailsDrawer />
      <MediaMoveDrawer />
      <MediaRestoreDrawer />
      <SharingLinkCreate />
      <MediaShareDelete />
      <MediaShareUpdate />
      <TermsAndConditions />
    </>
  )
}

export function EditorLayout({ children }: Readonly<PropsWithChildren<{}>>) {
  const { bucket, bucketUser, loading } = useBucket()

  if (loading) {
    return <Loading />
  }

  if (!getAuth().currentUser?.emailVerified) {
    return <RenderBlocker blocker={Blocker.EMAIL_NOT_VERIFIED} />
  }

  if (bucket?.isBanned) {
    return <RenderBlocker blocker={Blocker.BUCKET_DISABLED} />
  }

  if (bucketUser?.userStatus !== BucketUserStatus.Active) {
    return <RenderBlocker blocker={Blocker.NOT_FOUND} />
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppBar />
        <Wrapper>
          <Main id="main">{children}</Main>
        </Wrapper>
      </div>
      <AlbumDetailsDrawer />
      <Alerts />
      <ClearSelected />
      <MediaDelete />
      <MediaDetailsDrawer />
      <MediaMoveDrawer />
    </>
  )
}
