import { Alert, Button, styled, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import useEmailVerification from '../../hooks/useEmailVerification'
import AlertBubbleIcon from '../../../../svg/AlertBubbleIcon'
import { State } from '../blockers/types'

const Container = styled('div')(({ theme }) => ({
  margin: '20px',
  padding: '90px 20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 270px)',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    border: 'none'
  }
}))

const Content = styled('div')(({ theme }) => ({
  marginLeft: '30px',
  width: '65%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    '& p': {
      display: 'none'
    }
  }
}))

const StyledButton = styled(Button)((props) => ({
  marginTop: '20px',
  marginRight: '20px',
  marginBottom: '10px',
  textTransform: 'none',
  [props.theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

export default function Confirm() {
  const { id } = useParams<{ id: string }>()
  const { confirmState, confirmEmail, errorMessages, resendEmailConfirmation, resendState } = useEmailVerification()
  const confirmEmailRef = useRef(confirmEmail)

  // Verify that a email code is present in querystring
  // This code was sent with the confirmation email
  useEffect(() => {
    if (!id) {
      return
    }

    confirmEmailRef.current(id)
  }, [id])

  const handleResendEmail = () => {
    resendEmailConfirmation()
  }

  if (!id) {
    return (
      <Container>
        <Content>
          <Alert severity="error">Link is invalid or expired. Please try sending another.</Alert>
          <StyledButton
            disabled={[State.SUCCESS, State.LOADING].includes(resendState)}
            data-test="verifybtn-send-again"
            id="verifybtn-send-again"
            size="large"
            variant="contained"
            onClick={handleResendEmail}
          >
            Send Again
          </StyledButton>
        </Content>
      </Container>
    )
  }

  if (resendState === State.SUCCESS) {
    return (
      <Container>
        <Content>
          <Alert severity="success">Email sent successfully. Check your inbox.</Alert>
          <StyledButton data-test="verifybtn-send-again" id="verifybtn-send-again" size="large" variant="contained" onClick={handleResendEmail}>
            Didn&apos;t get it? Send Another
          </StyledButton>
        </Content>
      </Container>
    )
  }

  return (
    <Container>
      <Content>
        {confirmState === State.ERROR && (
          <>
            {errorMessages.map((message) => (
              <>
                <AlertBubbleIcon />
                <Typography variant="h5" sx={{ mt: 1 }}>
                  Confirmation Link Has Expired.
                </Typography>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {message}
                </Typography>
              </>
            ))}
            <StyledButton
              disabled={[State.SUCCESS, State.LOADING].includes(resendState)}
              data-test="verifybtn-send-again"
              id="verifybtn-send-again"
              size="large"
              variant="contained"
              onClick={handleResendEmail}
            >
              Send Again
            </StyledButton>
          </>
        )}
        {confirmState === State.DEFAULT && <Alert severity="info">Checking your confirmation code.</Alert>}
        {confirmState === State.LOADING && (
          <>
            <Loader />
            <Alert severity="info">Attempting to confirm your email address. Please wait.</Alert>
          </>
        )}
        {confirmState === State.SUCCESS && <Alert severity="success">Successfully confirmed your email.</Alert>}
      </Content>
    </Container>
  )
}
