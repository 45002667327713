import { FirebaseError } from '@firebase/util'
import { IdTokenResult, getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

export default function useDecodedIdToken(autoFetch = false) {
  const [result, setResult] = useState<IdTokenResult | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<FirebaseError | null>(null)

  useEffect(() => {
    if (!autoFetch || loading || result || error) return

    setLoading(true)

    getAuth()
      .currentUser?.getIdTokenResult()
      .then((value) => {
        setResult(value)
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        setLoading(false)
      })
  }, [autoFetch, error, loading, result])

  const customClaims = result?.claims

  return { customClaims, result, loading, error }
}
