import { Add, CloudDownload, Delete, DriveFileMove, Info } from '@mui/icons-material'
import MoreVert from '@mui/icons-material/MoreVert'
import ShareIcon from '@mui/icons-material/ShareOutlined'
import { MenuItem, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ScreenShareIcon from '@mui/icons-material/ScreenShareOutlined'
import Menu from '@mui/material/Menu'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BucketAlbum, BucketType, BucketUserRole } from '../../../../../graphql/generated'
import { activeAlbumIdVar, drawerVar, modalVar } from '../../../../../providers/apollo/cache'
import useBucket from '../../../hooks/useBucket'
import useBucketPlan from '../../../hooks/useBucketPlan'
import useBucketMigrationProgress from '../../../hooks/useBucketMigrationProgress'
import useBucketConvertProgress from '../../../hooks/useBucketConvertProgress'
import useProfilePlan from '../../../hooks/useProfilePlan'

interface Props {
  album: BucketAlbum
  openCreateSubAlbumForm: () => void
  downloadAlbums: (bucketId: string, albumIds: string[]) => void
}

export default function SecondaryActionsMenu({ album, openCreateSubAlbumForm, downloadAlbums }: Props) {
  const { bucket, bucketUser } = useBucket()
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { isSharingEnabled } = useProfilePlan()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isMigrationInProgressForBucket = useBucketMigrationProgress()
  const isBucketConvertInProgressForBucket = useBucketConvertProgress()
  const navigate = useNavigate()
  const isPersonalBucket = bucket?.bucketType === BucketType.Mybucket
  const isGroupBucket = bucket?.bucketType === BucketType.Group
  const isViewer = bucketUser?.userRole === BucketUserRole.Viewer

  // Album Create
  const albumCreateDisabled = !bucketUser?.permissions?.albumCreate || isMigrationInProgressForBucket || isBucketConvertInProgressForBucket
  const albumCreateRestricted = restrictions.modifyAlbums
  const albumCreateHideTooltip = albumCreateRestricted === false && albumCreateDisabled === false
  const albumShareDisabled = (isPersonalBucket && !isSharingEnabled) || (isGroupBucket && isViewer) || restrictions.sharing

  const albumShareTooltip = () => {
    if (!albumShareDisabled) {
      return ''
    }
    if (isPersonalBucket) {
      return "Your plan doesn't support sharing media."
    }

    return 'Your role for this bucket does not allow you to share albums.'
  }

  let albumCreateTooltipTitle
  if (albumCreateDisabled) {
    if (isMigrationInProgressForBucket || isBucketConvertInProgressForBucket) {
      albumCreateTooltipTitle = 'Album create is disabled during the migration process'
    } else {
      albumCreateTooltipTitle = 'Your role for this bucket does not allow you to create albums.'
    }
  } else {
    albumCreateTooltipTitle = tooltipTitle('modifyAlbums')
  }

  // Delete Album
  const albumDeleteDisabled = !bucketUser?.permissions?.albumDelete || isMigrationInProgressForBucket || isBucketConvertInProgressForBucket
  const albumDeleteHideTooltip = albumDeleteDisabled === false
  const albumDeleteTooltipTitle =
    isMigrationInProgressForBucket || isBucketConvertInProgressForBucket
      ? 'Album delete is disabled during the migration process'
      : 'Your role for this bucket does not allow you to delete albums.'

  // Move Album
  const albumMoveDisabled = !bucketUser?.permissions?.albumUpdate || isMigrationInProgressForBucket || isBucketConvertInProgressForBucket
  const albumMoveHideTooltip = albumDeleteDisabled === false
  const albumMoveTooltipTitle =
    isMigrationInProgressForBucket || isBucketConvertInProgressForBucket
      ? 'Album move is disabled during the migration process'
      : 'Your role for this bucket does not allow you to move albums.'

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenModal = (modal: 'bucketAlbumDelete' | 'bucketSharingLinkCreate') => () => {
    activeAlbumIdVar(album.id)
    modalVar(modal)
    setAnchorEl(null)
  }

  const handleOpenDrawer = (drawer: 'bucketAlbumDetails' | 'bucketAlbumMove') => () => {
    activeAlbumIdVar(album.id)
    drawerVar(drawer)
    setAnchorEl(null)
  }

  const handleDownload = () => {
    downloadAlbums(album.bucketId, [album.id])
    setAnchorEl(null)
  }

  const handleCreateSubAlbum = () => {
    if (albumCreateDisabled || albumCreateRestricted) return
    openCreateSubAlbumForm()
    setAnchorEl(null)
  }

  return (
    <>
      <ListItemSecondaryAction sx={{ right: 0 }}>
        <IconButton data-testid="album-more-actions" onClick={handleOpen} size="small" sx={{ borderRadius: 0 }}>
          <MoreVert fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'album-menu',
          role: 'listbox'
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem data-test="info-media-item" onClick={handleOpenDrawer('bucketAlbumDetails')}>
          <Info style={{ marginRight: 8 }} /> Album Details
        </MenuItem>
        <Tooltip title={albumShareTooltip()}>
          <span>
            <MenuItem disabled={albumShareDisabled} data-test="share-album-item" onClick={handleOpenModal('bucketSharingLinkCreate')}>
              <ShareIcon style={{ marginRight: 8 }} /> Share Album
            </MenuItem>
          </span>
        </Tooltip>
        {album.hasShares && (
          <Tooltip title={albumShareTooltip()}>
            <span>
              <MenuItem disabled={albumShareDisabled} data-test="share-album-item" onClick={() => navigate(`/bucket/${album.bucketId}/share`)}>
                <ScreenShareIcon style={{ marginRight: 8 }} /> Sharing Links
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <MenuItem data-test="download-menu-item" onClick={handleDownload}>
          <CloudDownload style={{ marginRight: 8 }} /> Download Album
        </MenuItem>
        <Tooltip
          PopperProps={{
            disablePortal: albumDeleteHideTooltip
          }}
          disableFocusListener={albumDeleteHideTooltip}
          disableHoverListener={albumDeleteHideTooltip}
          disableTouchListener={albumDeleteHideTooltip}
          title={albumDeleteTooltipTitle}
        >
          <div>
            <MenuItem data-testid="album-delete-menu-item" disabled={albumDeleteDisabled} onClick={handleOpenModal('bucketAlbumDelete')}>
              <Delete style={{ marginRight: 8 }} /> Delete Album
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip
          PopperProps={{
            disablePortal: albumMoveHideTooltip
          }}
          disableFocusListener={albumMoveHideTooltip}
          disableHoverListener={albumMoveHideTooltip}
          disableTouchListener={albumMoveHideTooltip}
          title={albumMoveTooltipTitle}
        >
          <div>
            <MenuItem data-test="move" disabled={albumMoveDisabled} id="move-button" onClick={handleOpenDrawer('bucketAlbumMove')}>
              <DriveFileMove style={{ marginRight: 8 }} /> Move Album
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip
          PopperProps={{
            disablePortal: albumCreateHideTooltip
          }}
          disableFocusListener={albumCreateHideTooltip}
          disableHoverListener={albumCreateHideTooltip}
          disableTouchListener={albumCreateHideTooltip}
          title={albumCreateTooltipTitle}
        >
          <div>
            <MenuItem disabled={albumCreateDisabled || albumCreateRestricted} data-test="create-subalbum-item" onClick={handleCreateSubAlbum}>
              <Add style={{ marginRight: 8 }} /> Create Album
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
    </>
  )
}
