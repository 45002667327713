import { ApolloError } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import React from 'react'
import logger from '../../utils/logger'
import { RumContext } from './RumProvider'

interface ExtendedErrorEvent extends Partial<ErrorEvent> {
  message: string
  type?: string
  context?: object
  metadata?: object
}

export default function useRum() {
  const rum = React.useContext(RumContext)

  const recordError = (error: ExtendedErrorEvent) => {
    if (!rum) {
      logger.warn('recordError: RUM is not enabled')
      return
    }
    rum.recordError(error)
  }

  const recordEvent = (eventType: string, eventData: object) => {
    if (!rum) {
      logger.warn('recordEvent: RUM is not enabled')
      return
    }
    rum.recordEvent(eventType, eventData)
  }

  const addSessionAttributes = (sessionAttributes: { [key: string]: string | boolean | number }) => {
    if (!rum) {
      logger.warn('addSessionAttributes: RUM is not enabled')
      return
    }
    rum.addSessionAttributes(sessionAttributes)
  }

  const generateErrorEvent = (type: string, error: Omit<ExtendedErrorEvent, 'type'>) => {
    const defaultContext = {
      url: window.location.href,
      email: getAuth()?.currentUser?.email,
      userId: getAuth()?.currentUser?.uid
    }
    const metadata = { ...defaultContext, ...error.context }
    const errorEvent = new ErrorEvent(type, { error: error.error, message: error.message }) as ExtendedErrorEvent
    errorEvent.metadata = metadata
    return errorEvent
  }

  const recordApolloError = (type: string, apolloError: ApolloError) => {
    apolloError.clientErrors.forEach((clientError) => {
      recordError(
        new ErrorEvent(type, {
          message: clientError.message,
          error: clientError
        })
      )
    })

    apolloError.graphQLErrors.forEach((graphQLError) => {
      recordError(
        new ErrorEvent(type, {
          message: graphQLError.message,
          error: graphQLError
        })
      )
    })

    apolloError.protocolErrors.forEach((protocolError) => {
      recordError(
        new ErrorEvent(type, {
          message: protocolError.message,
          error: protocolError
        })
      )
    })

    if (apolloError.networkError) {
      recordError(
        new ErrorEvent(type, {
          message: apolloError.networkError.message,
          error: apolloError.networkError
        })
      )
    }
  }

  return { addSessionAttributes, generateErrorEvent, recordError, recordApolloError, recordEvent }
}
