import { useApolloClient, useReactiveVar } from '@apollo/client'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PasswordVisibilityAdornment from '../../../../components/forms/PasswordVisibilityAdornment'
import { BucketShareFragmentFragmentDoc, BucketShareStatus, BucketShareType } from '../../../../graphql/generated'
import { activeShareIdVar, modalVar } from '../../../../providers/apollo/cache'
import decrypt from '../../../../utils/sharingLinkEncryption'
import useBucketShareUpdate from '../../hooks/useBucketShareUpdate'

interface Fields {
  albumId?: string | null
  password?: string | null
  passwordEnabled?: boolean | null
  title?: string | null
  scheduledDeletionAt?: string | null
  days?: 7 | 30 | 60 | 90 | null
}

export default function MediaShareUpdate() {
  const [errors, setErrors] = useState<{ title?: string; password?: string }>({})
  const shareId = useReactiveVar(activeShareIdVar)
  const client = useApolloClient()
  const share = client.readFragment({ id: `BucketShare:${shareId}`, fragment: BucketShareFragmentFragmentDoc })
  const [fields, setFields] = useState<Fields>({})
  const [showPassword, setShowPassword] = useState(false)
  const modal = useReactiveVar(modalVar)
  const { bucketId } = useParams<{ bucketId: string }>()
  const { mutation, results } = useBucketShareUpdate()
  const defaultText = fields.scheduledDeletionAt ? moment(fields.scheduledDeletionAt).format('MMM DD, YYYY') : '30 days'

  useEffect(() => {
    if (!share) return
    setFields({
      albumId: share?.albumId,
      password: share?.password ? decrypt(share?.password) : share?.password,
      passwordEnabled: share?.passwordProtected ?? false,
      title: share?.title,
      scheduledDeletionAt: share?.scheduledDeletionAt
    })
  }, [share])

  const handleChangeDaysField = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prevFields) => {
      let { scheduledDeletionAt } = prevFields
      if (event.target.name === 'days') {
        scheduledDeletionAt = moment().add(event.target.value, 'days').toISOString()
      }

      return {
        ...prevFields,
        scheduledDeletionAt,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleChangePasswordField = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prevFields) => {
      if (event.target.name === 'password' && event.target.value.trim() !== '') {
        setErrors({}) // reset the password error when the title field is not empty
      }

      return {
        ...prevFields,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleChangeTitleField = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prevFields) => {
      if (event.target.name === 'title' && event.target.value.trim() !== '') {
        setErrors({}) // reset the title error when the title field is not empty
      }

      return {
        ...prevFields,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleExpirationCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFields({ ...fields, days: 30, scheduledDeletionAt: moment().add(30, 'days').toISOString() }) // default to 30 days
      return
    }
    setFields({ ...fields, days: null, scheduledDeletionAt: null })
  }

  const handlePasswordCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFields({ ...fields, passwordEnabled: true, password: null })
      return
    }
    setFields({ ...fields, passwordEnabled: false, password: null })
  }

  const handleClose = () => {
    modalVar(null)
    results.reset()
  }

  const handleSubmit = () => {
    if (!bucketId || !shareId) return
    const { title, scheduledDeletionAt, password, albumId } = fields
    const status = BucketShareStatus.Active
    let variables
    if (albumId) {
      variables = { bucketId, albumId, shareId, status, title, scheduledDeletionAt, password, shareType: BucketShareType.Album }
    } else {
      variables = { bucketId, albumId, shareId, status, title, scheduledDeletionAt, password, shareType: BucketShareType.Media }
    }

    mutation({
      variables,
      onCompleted: () => {
        handleClose()
      }
    })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Dialog open={modal === 'bucketMediaShareUpdate'} onClose={handleClose} maxWidth="sm" fullWidth>
      {results.loading && <LinearProgress />}
      <DialogTitle>Edit Sharing Link</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: 1 }}
          label="Title"
          name="title"
          value={fields.title ?? ''}
          onChange={handleChangeTitleField}
          fullWidth
          error={!!errors.title}
          helperText={errors.title}
        />
        <Box sx={{ display: 'flex', marginTop: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={!!fields.passwordEnabled} onChange={handlePasswordCheckboxChange} />}
            label="Password Protected"
          />
          <TextField
            sx={{ marginTop: 1 }}
            label="Password"
            name="password"
            value={fields.password ?? ''}
            onChange={handleChangePasswordField}
            type={showPassword ? 'text' : 'password'}
            disabled={!fields.passwordEnabled}
            InputProps={{
              endAdornment: <PasswordVisibilityAdornment showPassword={showPassword} handleClickShowPassword={handleClickShowPassword} />
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', marginTop: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={!!fields.scheduledDeletionAt} onChange={handleExpirationCheckboxChange} />}
            label="Enable Link Expiration"
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarIcon />
                </InputAdornment>
              )
            }}
            value={fields.days ?? 0}
            onChange={handleChangeDaysField}
            name="days"
            select
            disabled={!fields.scheduledDeletionAt}
          >
            <MenuItem disabled value={0}>
              {defaultText}
            </MenuItem>
            <MenuItem value={7}>7 days</MenuItem>
            <MenuItem value={30}>30 days</MenuItem>
            <MenuItem value={60}>60 days</MenuItem>
            <MenuItem value={90}>90 days</MenuItem>
          </TextField>
        </Box>
      </DialogContent>

      <DialogActions style={{ marginTop: '12px' }}>
        <Button variant="outlined" color="primary" disabled={results.loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={results.loading || (!!fields.passwordEnabled && !fields.password)}
          onClick={handleSubmit}
        >
          Update Sharing Link
        </Button>
      </DialogActions>
    </Dialog>
  )
}
