import { Share } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BucketMedia, BucketType, BucketUserRole } from '../../../../../graphql/generated'
import { modalVar } from '../../../../../providers/apollo/cache'
import useSelectedMedia from '../../../hooks/useSelectedMedia'
import useBucketPlan from '../../../hooks/useBucketPlan'
import useProfilePlan from '../../../hooks/useProfilePlan'
import useBucket from '../../../hooks/useBucket'

interface Props {
  media?: BucketMedia | null
}

export default function MediaShare({ media }: Props) {
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { isSharingEnabled } = useProfilePlan()
  const { selectedMedia } = useSelectedMedia()
  const { bucket, bucketUser } = useBucket()
  const { bucketId } = useParams<{ bucketId: string }>()
  const mediaIds: string[] = media ? [media.id] : Array.from(selectedMedia.keys())

  const handleClick = () => {
    if (!bucketId || !mediaIds.length) return
    modalVar('bucketSharingLinkCreate')
  }

  const isPersonalBucket = bucket?.bucketType === BucketType.Mybucket
  const isGroupBucket = bucket?.bucketType === BucketType.Group
  const isViewer = bucketUser?.userRole === BucketUserRole.Viewer
  const hasMediaSelected = mediaIds.length > 0

  const canShare = () => {
    // Check permissions based on user's role within bucket for group bucket
    if (!hasMediaSelected || restrictions.sharing || (isGroupBucket && isViewer)) {
      return false
    }

    // Check permissions based on user's plan for personal bucket
    if (isPersonalBucket) {
      return isSharingEnabled
    }

    return true
  }

  const getTooltipTitle = () => {
    if (!hasMediaSelected) {
      return 'No Media Selected'
    }

    /**
     * Show tooltip if the bucket owner's plan has sharing restrictions, if the user is a viewer in a group bucket,
     * or if the user's plan doesn't support sharing
     */
    if (restrictions.sharing || (isGroupBucket && isViewer) || (isPersonalBucket && !isSharingEnabled)) {
      return tooltipTitle('sharing')
    }

    return 'Share Media'
  }

  return (
    <Tooltip title={getTooltipTitle()} arrow>
      <div>
        <IconButton color="inherit" aria-label="Share Media" disabled={!canShare()} onClick={handleClick} id="share-media" data-testid="share-media">
          <Share />
        </IconButton>
      </div>
    </Tooltip>
  )
}

MediaShare.defaultProps = {
  media: null
}
