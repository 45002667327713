import { useApolloClient } from '@apollo/client'
import { TuneOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BucketMediaFragmentFragmentDoc, BucketUserRole } from '../../../../../graphql/generated'
import useAlerts from '../../../hooks/useAlerts'
import useBucket from '../../../hooks/useBucket'
import useBucketPlan from '../../../hooks/useBucketPlan'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

export default function EditImage() {
  const { albumId, bucketId } = useParams<{ albumId: string; bucketId: string }>()
  const { createAlert } = useAlerts()
  const { selectedMedia } = useSelectedMedia()
  const client = useApolloClient()
  const { bucketUser } = useBucket()
  const { restrictions, tooltipTitle } = useBucketPlan()
  const navigate = useNavigate()
  const location = useLocation()

  const restricted = restrictions.imageEditor
  const getTooltipTitle = () => {
    if (restricted) {
      return tooltipTitle('imageEditor')
    }

    return selectedMedia.size ? 'Edit Image' : 'No Media Selected'
  }
  const title = getTooltipTitle()

  const handleClick = () => {
    const mediaId: string = selectedMedia.keys().next().value ?? ''
    if (!mediaId || !bucketId) {
      createAlert('There was an error fetching your bucket details')
      return
    }

    const media = client.readFragment({ id: `BucketMedia:${mediaId}`, fragment: BucketMediaFragmentFragmentDoc })
    if (!media) {
      createAlert('There was an error fetching this media')
      return
    }

    navigate(albumId ? `/bucket/${bucketId}/album/${albumId}/media/${mediaId}/editor` : `/bucket/${bucketId}/media/${mediaId}/editor`, {
      state: { from: location }
    })
  }

  return (
    <Tooltip title={title} arrow>
      <div>
        <IconButton
          disabled={restricted || selectedMedia.size !== 1 || [undefined, BucketUserRole.Viewer].includes(bucketUser?.userRole)}
          color="inherit"
          aria-label="Edit Image"
          onClick={handleClick}
          id="edit-image"
          data-test="buckets-appbar-edit-image"
        >
          <TuneOutlined />
        </IconButton>
      </div>
    </Tooltip>
  )
}
