import { LinearProgress } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Maintenance from '../components/Maintenance'
import Settings from './buckets/components/settings'
import ConfirmEmail from './buckets/ConfirmEmail'

const AccountRouter = lazy(() => import('./account/Router'))
const SubscriptionRouter = lazy(() => import('./subscription/Router'))
const AuthRouter = lazy(() => import('./auth/Router'))
const ConversionRouter = lazy(() => import('./conversion/Router'))
const BucketsRouter = lazy(() => import('./buckets/Router'))
const ManageBuckets = lazy(() => import('./buckets/ManageBuckets'))
const AcceptInvite = lazy(() => import('./buckets/AcceptInvite'))
const AcceptTransfer = lazy(() => import('./buckets/AcceptBucketOwnerInvite'))
const SharedRouter = lazy(() => import('./shared/Router'))
const Create = lazy(() => import('./buckets/Create'))

export default function Router() {
  const location = useLocation()
  const maintenanceIsEnabled = useFlag('maintenanceIsEnabled')

  return (
    <>
      {maintenanceIsEnabled && <Maintenance />}
      <Suspense fallback={<LinearProgress />}>
        <Routes>
          {/** Redirects from legacy site */}
          <Route path="explore" element={<Navigate to="/" />} />
          <Route path="login" element={<Navigate to="/auth/login" />} />
          <Route path="resetPassword" element={<Navigate to={`/auth/reset-password${location.search}`} />} />
          <Route path="confirmation" element={<Navigate to={`/auth/confirmation${location.search}`} />} />
          {/** Primary routes */}
          <Route path="transfer/:transferId" element={<AcceptTransfer />} />
          <Route path="invite/:invitationId" element={<AcceptInvite />} />
          <Route path="share/:shareId/*" element={<SharedRouter />} />
          <Route path="buckets" element={<ManageBuckets />} />
          <Route path="settings" element={<Settings />} />
          <Route path="subscription/*" element={<SubscriptionRouter />} />
          <Route path="view-by-admin/*" />
          <Route path="confirm/:id" element={<ConfirmEmail />} />
          <Route path="confirm/" element={<ConfirmEmail />} />
          <Route path="bucket/create" element={<Create />} />
          <Route path="bucket/:bucketId/*" element={<BucketsRouter />} />
          <Route path="auth/*" element={<AuthRouter />} />
          <Route path="u/:userId" element={<Navigate to="/buckets" />} />
          <Route path="u/:userId/*" element={<AccountRouter />} />
          <Route path="*" element={<ConversionRouter />} />
        </Routes>
      </Suspense>
    </>
  )
}
