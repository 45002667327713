import { useApolloClient } from '@apollo/client'
import { FavoriteBorderOutlined, FavoriteOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BucketMedia, BucketMediaFragmentFragmentDoc } from '../../../../../graphql/generated'
import useBucketBulkMediaUpdate from '../../../hooks/useBucketBulkMediaUpdate'
import useBucketMediaFavoriteCacheUpdate from '../../../hooks/useBucketMediaFavoriteCacheUpdate'
import useSelectedMedia from '../../../hooks/useSelectedMedia'
import useBucketPlan from '../../../hooks/useBucketPlan'

interface Props {
  color?: 'inherit' | 'primary' | 'secondary'
  isDisabled?: boolean
  onCompleted?: () => void
}

export default function MediaFavorite({ isDisabled, color, onCompleted }: Props) {
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { selectedMedia } = useSelectedMedia()
  const { bucketId } = useParams<{ bucketId: string }>()
  const mediaIds: string[] = Array.from(selectedMedia.keys())
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const { mutation: bucketBulkMediaUpdate } = useBucketBulkMediaUpdate()
  const client = useApolloClient()
  const clientRef = useRef(client)
  const { updateCache } = useBucketMediaFavoriteCacheUpdate()

  const restricted = restrictions.favorites
  const unrestrictedTitle = isFavorite ? 'Remove from Favorites' : 'Add to Favorites'
  const getTooltipTitle = () => {
    if (restricted) {
      return tooltipTitle('favorites')
    }

    return selectedMedia.size ? unrestrictedTitle : 'No Media Selected'
  }
  const title = getTooltipTitle()

  useEffect(() => {
    const stack = [...mediaIds]
    while (stack.length) {
      const mediaId = stack.pop()
      if (!mediaId) break

      const media = clientRef.current.readFragment<BucketMedia>({
        id: `BucketMedia:${mediaId}`,
        fragment: BucketMediaFragmentFragmentDoc
      })

      if (!media) break

      if (media.isFavorite) {
        setIsFavorite(media.isFavorite)
        break
      }
    }
  }, [mediaIds])

  const handleFavorite = () => {
    if (!bucketId || !mediaIds.length) return
    const variables = {
      bucketId,
      mediaIds,
      data: { isFavorite: !isFavorite }
    }
    bucketBulkMediaUpdate({
      variables,
      update: (_, results) => {
        if (!results.data?.bucketBulkMediaUpdate) return

        const updatedMediaIds = results.data.bucketBulkMediaUpdate
        updatedMediaIds.forEach((id) => {
          client.cache.modify({
            id: `BucketMedia:${id}`,
            fields: {
              isFavorite: (cachedValue) => !cachedValue
            }
          })

          const media = client.readFragment<BucketMedia>({
            id: `BucketMedia:${id}`,
            fragment: BucketMediaFragmentFragmentDoc
          })

          if (!media) return
          updateCache(media)
        })
      }
    })
    setIsFavorite(!isFavorite)
    if (onCompleted) {
      onCompleted()
    }
  }

  return (
    <Tooltip title={title} arrow>
      <div>
        <IconButton
          color={color}
          aria-label={unrestrictedTitle}
          disabled={restricted || isDisabled || selectedMedia.size === 0}
          data-testid="appbar-favorite"
          id={isFavorite ? 'remove-favorite-button' : 'add-favorite-button'}
          onClick={handleFavorite}
        >
          {isFavorite ? <FavoriteOutlined /> : <FavoriteBorderOutlined />}
        </IconButton>
      </div>
    </Tooltip>
  )
}

MediaFavorite.defaultProps = {
  color: 'inherit',
  onCompleted: undefined,
  isDisabled: false
}
