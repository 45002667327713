import CloudUpload from '@mui/icons-material/CloudUpload'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import { getAuth } from 'firebase/auth'
import { ChangeEvent } from 'react'
import { useParams, useMatch } from 'react-router-dom'
import { BucketUserRole } from '../../../../graphql/generated'
import useBucket from '../../hooks/useBucket'
import useBucketCredentials from '../../hooks/useBucketCredentials'
import useBucketMediaUpload from '../../hooks/useBucketMediaUpload'
import useBucketPlan from '../../hooks/useBucketPlan'
import useBucketMigrationProgress from '../../hooks/useBucketMigrationProgress'
import useBucketConvertProgress from '../../hooks/useBucketConvertProgress'

// Aligns with constants kept in datasources
export const SUPPORTED_VIDEO_TYPES = [
  '.3gp2',
  '.asf',
  '.mov',
  '.vob',
  '.mp4',
  '.flv',
  '.webm',
  '.mpg',
  '.mpeg',
  '.dvd',
  '.mkv',
  '.swf',
  '.3gp',
  '.avi',
  '.quicktime'
]
export const SUPPORTED_IMAGE_TYPES = ['.webp', '.jpg', '.png', '.jpeg', 'bmp', '.gif', '.heic', '.tiff', '.tif', '.heif']

export default function UploadMenu() {
  const userId = getAuth().currentUser?.uid
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { bucketUser } = useBucket()
  const { bucketId } = useParams<{ bucketId: string }>()
  const { uploadMultiple } = useBucketMediaUpload()
  const { query } = useBucketCredentials()
  const isMigrationInProgressForBucket = useBucketMigrationProgress()
  const isBucketConvertInProgressForBucket = useBucketConvertProgress()

  const disabled =
    (bucketUser?.userRole === BucketUserRole.Viewer && !bucketUser?.permissions?.mediaCreate) ||
    isMigrationInProgressForBucket ||
    isBucketConvertInProgressForBucket
  const restricted = restrictions.uploadMedia || disabled
  const path = useMatch('/bucket/:bucketId/:slug')
  const isActivityFeed = path?.params.slug === 'activity'
  const hideTooltip = !restricted
  const title = tooltipTitle('uploadMedia')
  const userCannotCreateMediaTitle =
    isMigrationInProgressForBucket || isBucketConvertInProgressForBucket
      ? 'Media upload is disabled during the migration process'
      : 'You do not have permission to upload media'

  const handleClick = () => {
    if (!bucketId || !userId) return
    query({ variables: { bucketId, userId } })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    uploadMultiple(event.target.files)
  }

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (disabled) {
      event.preventDefault()
    }
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  const acceptList = `${[...SUPPORTED_IMAGE_TYPES, ...SUPPORTED_VIDEO_TYPES].join(', ')}`

  return (
    <label htmlFor="contained-button-file" data-test="label-media-upload">
      <input
        accept={acceptList}
        style={{ opacity: 0, position: 'absolute', width: '20px' }}
        id="contained-button-file"
        type="file"
        multiple
        onChange={handleChange}
        onClick={onInputClick}
        data-testid="file-upload-appbar"
      />
      <Tooltip
        PopperProps={{
          disablePortal: hideTooltip
        }}
        disableFocusListener={hideTooltip}
        disableHoverListener={hideTooltip}
        disableTouchListener={hideTooltip}
        title={disabled ? userCannotCreateMediaTitle : title}
      >
        <div>
          <Button
            sx={{ textTransform: 'none', marginRight: '12px' }}
            component="span"
            data-testid="appbar-upload"
            disabled={restricted || isActivityFeed}
            aria-label="Upload Media"
            id="appbar-upload"
            size="small"
            variant="outlined"
            onClick={handleClick}
          >
            <CloudUpload style={{ marginRight: 8 }} />
            Upload
          </Button>
        </div>
      </Tooltip>
    </label>
  )
}
