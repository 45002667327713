import { useQuery, useReactiveVar } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { PropsWithChildren } from 'react'
import Loader from '../components/Loader'
import { SubscriptionStatus, UserProfileDocument } from '../graphql/generated'
import { adminUserIdVar } from './apollo/cache'

interface Props {
  redirectUrl?: string
  /**
   * A valid firebase session means the user is logged in via firebase
   */
  validFirebaseSession?: boolean
  /**
   * An active subscription means the user meets one or more of the following criteria:
   * 1. The user has an active subscription
   * 2. The user has access to a group bucket with an active subscription
   */
  activeSubscription?: boolean
}

export default function Require({ validFirebaseSession, activeSubscription, redirectUrl, children }: PropsWithChildren<Props>) {
  const userProfileQuery = useQuery(UserProfileDocument, { skip: !activeSubscription })
  const isAdmin = useReactiveVar(adminUserIdVar)

  const url = redirectUrl

  if (validFirebaseSession && !getAuth().currentUser) {
    window.location.href = url || `/auth/login?returnUrl=${window.location.href}`
    return <Loader />
  }

  // by this point, we know the user is logged in
  if (activeSubscription) {
    if (!userProfileQuery.called || userProfileQuery.loading) {
      return <Loader />
    }

    const isGroupBucketMember = userProfileQuery?.data?.userProfile?.subscription?.isGroupBucketMember
    const subscriptionStatus = userProfileQuery?.data?.userProfile?.subscription?.subscription?.status

    // User needs to buy active subscription to access the view, if they don't have one
    // redirect them to the select plan page
    if (!isGroupBucketMember && subscriptionStatus !== SubscriptionStatus.Active && !isAdmin) {
      window.location.href = url || '/subscription/select-plan'
      return <Loader />
    }
  }

  return <>{children}</>
}

Require.defaultProps = {
  redirectUrl: '',
  validFirebaseSession: true,
  activeSubscription: false
}
