import { DeleteOutline } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { activeAlbumIdVar, modalVar } from '../../../../../providers/apollo/cache'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'
import useBucket from '../../../hooks/useBucket'
import useBucketMigrationProgress from '../../../hooks/useBucketMigrationProgress'
import useBucketConvertProgress from '../../../hooks/useBucketConvertProgress'

export default function AlbumDeleteIcon() {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const { selectedAlbums } = useSelectedAlbums()
  const containRootAlbum = Array.from(selectedAlbums.keys()).includes(bucketId as string)
  const { bucketUser } = useBucket()
  const albumDeletePermission = bucketUser?.permissions?.albumDelete
  const isMigrationInProgressForBucket = useBucketMigrationProgress()
  const isBucketConvertInProgressForBucket = useBucketConvertProgress()

  let title = 'Delete Album'
  if (isMigrationInProgressForBucket || isBucketConvertInProgressForBucket) {
    title = 'Delete album is disabled during the migration process'
  } else if (!albumDeletePermission) {
    title = 'Your role for this bucket does not allow you to delete albums.'
  }

  const disabled =
    containRootAlbum ||
    !(albumId || selectedAlbums.size !== 0) ||
    !albumDeletePermission ||
    isMigrationInProgressForBucket ||
    isBucketConvertInProgressForBucket

  const handleClick = () => {
    if (!albumId && selectedAlbums.size < 1) return

    if (albumId) activeAlbumIdVar(albumId)
    modalVar('bucketAlbumDelete')
  }

  return (
    <Tooltip title={title} arrow>
      <div>
        <IconButton
          disabled={disabled}
          color="inherit"
          aria-label="Delete Album"
          onClick={handleClick}
          id="move-to-trash-button"
          data-test="move-to-trash-button"
        >
          <DeleteOutline />
        </IconButton>
      </div>
    </Tooltip>
  )
}
