import { useState, MouseEvent } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery, useTheme, Menu, IconButton } from '@mui/material'
import MoreVert from '@mui/icons-material/MoreVert'
import AlbumDeleteIcon from './iconButtons/AlbumDelete'
import AlbumDetailsIcon from './iconButtons/AlbumDetails'
import AlbumDownloadIcon from './iconButtons/AlbumDownload'
import AlbumMoveIcon from './iconButtons/AlbumMove'
import AlbumDelete from './menuItems/AlbumDelete'
import AlbumDetails from './menuItems/AlbumDetails'
import AlbumDownload from './menuItems/AlbumDownload'
import AlbumMove from './menuItems/AlbumMove'
import MediaDelete from './iconButtons/MediaDelete'
import MediaDetails from './iconButtons/MediaDetails'
import MediaDownload from './iconButtons/MediaDownload'
import MediaMove from './iconButtons/MediaMove'
import MediaPrintShop from './iconButtons/MediaPrintShop'
import PlaySlideshow from './iconButtons/PlaySlideshow'
import EditImage from './iconButtons/EditImage'
import MediaShare from './iconButtons/MediaShare'
import MediaFavorite from './iconButtons/MediaFavorite'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'

function MobileDropdownMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton color="inherit" onClick={handleClick} data-test="appbar-dropdown-toggle" id="appbar-dropdown-toggle" size="large">
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} keepMounted>
        <AlbumDetails handleClose={handleClose} />
        <AlbumDownload handleClose={handleClose} />
        <AlbumDelete handleClose={handleClose} />
        <AlbumMove handleClose={handleClose} />
      </Menu>
    </>
  )
}

function AlbumIconMenu({ mobile }: { mobile: boolean }) {
  const location = useLocation()
  const isAlbumPath = location.pathname.includes('/album')

  if (mobile && !isAlbumPath) return null

  return (
    <>
      {!mobile ? (
        <>
          <AlbumDetailsIcon />
          <AlbumDownloadIcon />
          <AlbumDeleteIcon />
          <AlbumMoveIcon />
          <PlaySlideshow />
        </>
      ) : (
        <>
          <PlaySlideshow />
          <MobileDropdownMenu />
        </>
      )}
    </>
  )
}

function MediaIconMenu() {
  return (
    <>
      <MediaDetails />
      <MediaShare />
      <MediaDownload />
      <MediaFavorite />
      <MediaDelete />
      <MediaMove />
      <EditImage />
      <MediaPrintShop />
    </>
  )
}

export default function GalleryIconButtons() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { selectedModeEnabled: selectedModeEnabledAlbums } = useSelectedAlbums()
  const { selectedModeEnabled: selectedModeEnabledMedia } = useSelectedMedia()
  const selectedModeEnabled = selectedModeEnabledAlbums || selectedModeEnabledMedia

  if (isMobile && selectedModeEnabled) {
    return null
  }

  if (!selectedModeEnabled) {
    return <AlbumIconMenu mobile={isMobile} />
  }
  return <MediaIconMenu />
}
