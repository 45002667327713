import { useUnleashContext } from '@unleash/proxy-client-react'
import { getAuth } from 'firebase/auth'
import rg4js from 'raygun4js'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import { useAnalytics } from 'use-analytics'
import Loader from '../components/Loader'
import config from '../config'
import logger from '../utils/logger'
import useGAEvent from '../views/auth/hooks/useGAEvent'
import { convertedVar } from './apollo/cache'
import { RumContext } from './rum/RumProvider'
import useRum from './rum/useRum'

export default function FirebaseBlockingProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const rum = React.useContext(RumContext)
  const isRumEnabled = Boolean(rum)
  const [initialized, setInitialized] = useState(false)
  const updateContext = useUnleashContext()
  const updateContextRef = useRef(updateContext)
  const { addSessionAttributes } = useRum()
  const addSessionAttributesRef = useRef(addSessionAttributes)
  const { user: setAnalyticUser, identify } = useAnalytics()
  const { trackEvent } = useGAEvent()
  const trackEventRef = useRef(trackEvent)
  const setAnalyticUserRef = useRef(setAnalyticUser)
  const identifyRef = useRef(identify)

  useEffect(() => {
    getAuth().onIdTokenChanged((user) => {
      if (!user) {
        return
      }

      const cookies = new Cookies()
      updateContextRef
        .current({ userId: user.uid })
        .then(async () => {
          const token = await user.getIdToken()
          cookies.set('app_auth', token, config.cookieOptions)
        })
        .catch((error) => {
          logger.error(error)
        })
    })
  }, [])

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      const cookies = new Cookies()

      if (!user) {
        setInitialized(true)
        rg4js('endSession')
        // TODO: delete when deprecating old architecture
        convertedVar(false)
        return
      }

      rg4js('setUser', {
        identifier: user.uid,
        isAnonymous: false,
        email: user.email ?? undefined,
        fullName: user.displayName ?? undefined
      })

      // GA Analytics track user
      setAnalyticUserRef.current(user.uid)
      // TODO: confirm if we need planId for identifying user
      identifyRef.current(user.uid, { ...config.app })
      // Profitwell tracking
      trackEventRef.current('start_pw', { pw_user_email: user.email })

      updateContextRef
        .current({ userId: user.uid })
        .then(async () => {
          const token = await user.getIdToken()
          cookies.set('app_auth', token, config.cookieOptions)
          setInitialized(true)

          // TODO: delete when deprecating old architecture
          const idTokenResult = await user.getIdTokenResult()
          const isConverted = Boolean(idTokenResult?.claims?.isConverted)
          convertedVar(isConverted)
        })
        .catch((error) => {
          logger.error(error)
        })
    })
  }, [])

  // Adds session data to RUM once initialized without risk of missing user data due to RUM not being initialized
  // yet in a race condition.
  useEffect(() => {
    if (!isRumEnabled) {
      return
    }

    getAuth().onAuthStateChanged((user) => {
      if (!user) {
        return
      }
      addSessionAttributesRef.current({ userId: user?.uid, email: user?.email ?? '' })
      logger.info('FirebaseBlockingProvider: added session attributes')
    })
  }, [isRumEnabled])

  if (!initialized) {
    return <Loader />
  }

  return <>{children}</>
}
