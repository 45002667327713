import { useApolloClient, useLazyQuery } from '@apollo/client'
import CartIcon from '@mui/icons-material/ShoppingCartOutlined'
import { MenuItem } from '@mui/material'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { s3Media } from '../../../../../config'
import { BucketMedia, BucketMediaByIdsDocument, BucketMediaFragmentFragmentDoc } from '../../../../../graphql/generated'
import useAlerts from '../../../hooks/useAlerts'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

interface Props {
  media?: BucketMedia | null
}
export default function MediaPrintShop({ media }: Props) {
  const { selectedMedia, clearSelectedMedia } = useSelectedMedia()
  const selectedMediaIds = media ? [media.id] : Array.from(selectedMedia.keys())
  const { bucketId } = useParams<{ bucketId: string }>()
  const { createAlert } = useAlerts()
  const client = useApolloClient()
  const [heicUrls, setHeicUrls] = useState<string[]>([])

  const [query] = useLazyQuery(BucketMediaByIdsDocument, {
    // Direct links are signed with an expiring token. Therefore we must refetch to get a new token
    fetchPolicy: 'no-cache',
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
        })
        return
      }
      createAlert('There was a problem retrieving your image. Please try again later.')
    },
    onCompleted: ({ bucketMediaByIds }) => {
      if (!bucketMediaByIds) return
      const urls: string[] = []
      bucketMediaByIds.forEach((bucketMedia) => {
        const signedUrl = encodeURIComponent(bucketMedia.signedUrl || '')
        urls.push(signedUrl)
      })

      urls.push(...heicUrls)

      if (!isEmpty(urls)) {
        const url = `https://www.printshoplab.com/prints/quick-order?source=${urls.join(';')}`
        window.open(url, '_blank')
      }

      clearSelectedMedia()
    }
  })

  const handleClick = () => {
    if (!bucketId || !selectedMediaIds.length) return

    const heics: string[] = []
    const mediaIds: string[] = []

    selectedMediaIds.forEach((id) => {
      const item = client.readFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc })
      if (item?.filename?.toLowerCase().includes('.heic')) {
        // heic files must use a hosting domain link rather than a direct link
        const url = `${s3Media.domain}/${item.bucketId}/${item.filename}`
        heics.push(encodeURIComponent(url))
      } else if (!item?.isVideo) {
        mediaIds.push(id)
      }
    })

    setHeicUrls(heics)

    query({ variables: { bucketId, mediaIds } })
  }

  return (
    <MenuItem
      aria-label="Order Prints"
      disabled={isEmpty(selectedMediaIds)}
      onClick={handleClick}
      color="inherit"
      data-test="order-prints-menu-item"
      id="order-prints-button-menu-item"
    >
      <CartIcon style={{ marginRight: 8 }} /> Buy Prints
    </MenuItem>
  )
}

MediaPrintShop.defaultProps = {
  media: null
}
