import { ApolloError, useMutation } from '@apollo/client'
import { SubscriptionUpateDocument, SubscriptionUpdateInput } from '../../../graphql/generated'

export default function useSubscriptionUpdate() {
  const [mutation, response] = useMutation(SubscriptionUpateDocument)

  const updateSubscription = async (subscriptionId: string, input: SubscriptionUpdateInput, handleError?: (apolloError: ApolloError) => void) => {
    try {
      const result = await mutation({
        variables: { subscriptionId, input },
        onCompleted: () => {
          window.location.reload()
        }
      })
      return result
    } catch (err) {
      const apolloError = err as ApolloError
      if (handleError) {
        handleError(apolloError)
      }
      return null
    }
  }

  return {
    updateSubscription,
    data: response.data,
    error: response.error,
    loading: response.loading
  }
}
